import { doc, p } from '@atlaskit/adf-utils/builders';

import type {
	InsertStorageFragmentRequestPayload,
	IssueCreateSidePanelActions,
} from '@confluence/issue-create-side-panel';
import {
	ISSUE_CREATE_SIDE_PANEL_ID,
	ISSUE_CREATE_SIDE_PANEL_WIDTH,
	ISSUE_CREATE_WITH_AI_SIDE_PANEL_ID,
} from '@confluence/issue-create-side-panel';
import type { RightSidebarContextType } from '@confluence/page-layout-context';

import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';

export const SUMMARY_MAX_LENGTH = 254;

const splitText = (text: string, maxLength: number): [string, string] => {
	const hellip = '…';
	if (text.length <= maxLength) {
		return [text, ''];
	}
	// Calculate the length of the first part without including the ellipsis
	const firstPartLength = maxLength - hellip.length;
	// Split the text into two parts
	const firstPart = text.substring(0, firstPartLength) + hellip;
	const secondPart = text.substring(firstPartLength);
	return [firstPart, secondPart];
};

const getLastFetchTimeFromMetaTag = (): number | null => {
	const metaTag = document.querySelector(`meta[name="confluence-request-time"]`);
	return metaTag ? Number(metaTag.getAttribute('content')) : null;
};

const constructInsertStorageFragmentRequestPayload = (
	range,
	contentId,
	contentRef,
	lastModified?,
) => {
	const {
		searchText: { numMatches, index: matchIndex, selectedText },
	} = convertSelectionToLegacyFormat(contentRef, range, contentId);

	return {
		pageId: contentId,
		selectedText,
		index: matchIndex,
		numMatches,
		// Get last fetch time of current page.
		// This used to be the request time, but this caused problems due to time drift between the server and database.
		// So now try to use the content last modification date on load if that exists, then fallback onto the
		// request time if it's not available.
		lastFetchTime: lastModified ? new Date(lastModified).getTime() : getLastFetchTimeFromMetaTag(),
		xmlModification: undefined,
	} as InsertStorageFragmentRequestPayload;
};

export interface CreateSingleJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	container: HTMLElement;
	lastModified?: string | null;
	isCreatedWithAi?: boolean;
}

export interface CreateSingleAiJiraIssueHandleParams {
	rightSidebarContext: RightSidebarContextType;
	actions: IssueCreateSidePanelActions;
	activeSelection?: Selection | null;
	contentId: string;
	container: HTMLElement;
	lastModified?: string | null;
}

export const createSingleJiraIssueHandle =
	({
		rightSidebarContext,
		actions,
		activeSelection,
		contentId,
		container,
		lastModified,
	}: CreateSingleJiraIssueHandleParams) =>
	() => {
		const selectionString = activeSelection?.toString() || '';
		const insertStorageFragmentRequestPayload = constructInsertStorageFragmentRequestPayload(
			activeSelection?.getRangeAt(0),
			contentId,
			container,
			lastModified,
		);
		const [summary, description] = splitText(selectionString, SUMMARY_MAX_LENGTH);
		actions.setSummary(summary);
		actions.setDescriptionAdf(doc(p(description)));
		actions.setInsertStorageFragmentRequestPayload(insertStorageFragmentRequestPayload);

		rightSidebarContext.open(ISSUE_CREATE_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
	};

export const createSingleAiJiraIssueHandle =
	({
		rightSidebarContext,
		actions,
		activeSelection,
		contentId,
		container,
		lastModified,
	}: CreateSingleAiJiraIssueHandleParams) =>
	() => {
		const docNodeSelection = doc(p(activeSelection?.toString() || ''));
		const insertStorageFragmentRequestPayload = constructInsertStorageFragmentRequestPayload(
			activeSelection?.getRangeAt(0),
			contentId,
			container,
			lastModified,
		);

		actions.setHighlightedText(docNodeSelection);
		actions.setInsertStorageFragmentRequestPayload(insertStorageFragmentRequestPayload);

		rightSidebarContext.open(ISSUE_CREATE_WITH_AI_SIDE_PANEL_ID, ISSUE_CREATE_SIDE_PANEL_WIDTH);
	};
