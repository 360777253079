import React, { useCallback } from 'react';

import type { DocNode } from '@atlaskit/adf-schema';

import type { IssueCreateEmbedSuccessPayload } from '@atlassian/gic-anywhere';
import { GicAnywhere } from '@atlassian/gic-anywhere';
import { ConfluenceAiIssueCreate } from '@atlassian/ai-issue-create';

import { cfetch } from '@confluence/network';
import { useSessionData } from '@confluence/session-data';

import type { IssueCreateSidePanelBodyProps } from './__types__/IssueCreateSidePanelBody';
import { useIssueCreateSidePanelContext } from './IssueCreateSidePanelContextProvider';
import type { InsertStorageFragmentRequestPayload } from './__types__/InsertStorageFragmentRequestPayload';

const noop = () => {};

export const IssueCreateSidePanelBody = ({
	onClose,
	isCreatedWithAi,
}: IssueCreateSidePanelBodyProps) => {
	const { cloudId } = useSessionData();

	const [state] = useIssueCreateSidePanelContext();

	const handleSuccess = useCallback(
		(payload) => {
			onSuccess(payload, state);
		},
		[state],
	);

	return (
		<>
			{isCreatedWithAi ? (
				<ConfluenceAiIssueCreate
					cloudId={cloudId}
					confluencePage="https://hello.atlassian.net/wiki/spaces/~701210b0399f03a204a2496095a926e0f0802/pages/2509555448/Memory"
					highlightedText={state.highlightedText}
					consumer="confluence-highlight-issue-create"
					onSuccess={handleSuccess}
					onFailure={noop}
					onClose={onClose}
					onStateChange={noop}
				/>
			) : (
				<GicAnywhere
					consumer="confluence-highlight-issue-create"
					onSuccess={handleSuccess}
					onClose={onClose}
					onFailure={noop}
					summary={state.summary}
					descriptionAdf={state.descriptionAdf}
				/>
			)}
		</>
	);
};

export const constuctSmartlinkTag = (url: string) => {
	return `<a href="${url}" data-card-appearance="inline">${url}</a>`;
};

const onSuccess = (
	payload: IssueCreateEmbedSuccessPayload,
	state: {
		summary?: string;
		descriptionAdf?: DocNode;
		insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload | undefined;
	},
) => {
	const insertStorageFragmentRequestPayload = state.insertStorageFragmentRequestPayload;
	if (insertStorageFragmentRequestPayload) {
		insertStorageFragmentRequestPayload.xmlModification = constuctSmartlinkTag(payload.link);
		insertContentAtSelectionEnd(insertStorageFragmentRequestPayload)
			.then((_data) => noop)
			.catch((_error) => noop);
	}
};

const insertContentAtSelectionEnd = async (
	insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload,
) => {
	try {
		const restUrl = '/wiki/rest/highlighting/1.0/insert-storage-fragment';

		const response = await cfetch(restUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(insertStorageFragmentRequestPayload),
		});

		if (!response.ok) {
			throw new Error('Request failed');
		}

		return await response.json();
	} catch (error) {}
};
