import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';

import { useRightSidebarContext } from '@confluence/page-layout-context';
import { useIssueCreateSidePanelContext } from '@confluence/issue-create-side-panel';

import { createMultipleJiraIssuesHandle } from './builtInButtonHandlers';
import { AtlassianIntelligenceIcon } from './AtlassianIntelligenceIcon';
import {
	createSingleAiJiraIssueHandle,
	createSingleJiraIssueHandle,
} from './createJiraIssueHandlers';

type CreateJiraIssueActionsProps = {
	activeSelection?: Selection | null;
	completeKey: string;
	container: any;
	contentId: string;
	lastModified?: string | null;
	showCreateMultipleIssuesButton: boolean;
	showAtlassianIntelligenceCreateButton: boolean;
};

const createWithAiDropdownItemStyle = xcss({
	display: 'flex',
	alignItems: 'center',
});

const aiIconStyle = xcss({
	paddingLeft: 'space.100',
	paddingRight: 'space.250',
	lineHeight: '8px',
});

export const CreateJiraIssueActions = ({
	activeSelection,
	completeKey,
	container,
	contentId,
	lastModified,
	showCreateMultipleIssuesButton,
	showAtlassianIntelligenceCreateButton,
}: CreateJiraIssueActionsProps) => {
	const rightSidebarContext = useRightSidebarContext();
	const [, actions] = useIssueCreateSidePanelContext();
	const shouldShowDropdown =
		showCreateMultipleIssuesButton || showAtlassianIntelligenceCreateButton;
	return shouldShowDropdown ? (
		<DropdownMenu
			testId="create-jira-issue-dropdown"
			shouldRenderToParent
			trigger={({ triggerRef, ...props }) => (
				<Button
					{...props}
					id="create-jira-issue-trigger"
					appearance="subtle"
					iconBefore={<EditorAddIcon size="medium" label="" />}
					iconAfter={<ChevronDownIcon size="medium" label="Drop down" />}
					ref={triggerRef}
				>
					<FormattedMessage
						id="highlight-actions.create-jira-issue"
						defaultMessage="Jira issue"
						description="Create a new Jira issue"
					/>
				</Button>
			)}
		>
			<DropdownItemGroup>
				<DropdownItem
					onClick={createSingleJiraIssueHandle({
						rightSidebarContext,
						actions,
						activeSelection,
						contentId,
						container,
						lastModified,
					})}
				>
					<FormattedMessage
						id="highlight-actions.single-issue"
						defaultMessage="Create single issue"
						description="Create a single Jira issue"
					/>
				</DropdownItem>
				{showCreateMultipleIssuesButton && (
					<DropdownItem
						onClick={createMultipleJiraIssuesHandle(container, contentId, lastModified)}
					>
						<FormattedMessage
							id="highlight-actions.multiple-issues"
							defaultMessage="Multiple issues"
							description="Create multiple Jira issues from a table"
						/>
					</DropdownItem>
				)}
				{showAtlassianIntelligenceCreateButton && (
					<DropdownItem
						onClick={createSingleAiJiraIssueHandle({
							rightSidebarContext,
							actions,
							activeSelection,
							contentId,
							container,
							lastModified,
						})}
					>
						<Box xcss={createWithAiDropdownItemStyle}>
							<FormattedMessage
								id="highlight-actions.use-atlassian-intelligence.description"
								defaultMessage="Create with Atlassian Intelligence"
								description="Create a single Jira issue using Atlassian Intelligence"
							/>
							<Box xcss={aiIconStyle}>
								<AtlassianIntelligenceIcon size="large" />
							</Box>
							<Lozenge
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									color: 'color.icon',
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									backgroundColor: 'color.icon.disabled',
								}}
							>
								<FormattedMessage
									id="highlight-actions.use-atlassian-intelligence.beta-lozenge"
									defaultMessage="beta"
									description="Beta lozenge letting the user know that ai features are in beta state"
								/>
							</Lozenge>
						</Box>
					</DropdownItem>
				)}
			</DropdownItemGroup>
		</DropdownMenu>
	) : (
		<Button
			id="create-jira-issue-button"
			appearance="subtle"
			iconBefore={<EditorAddIcon size="medium" label="" />}
			data-key={completeKey}
			onClick={createSingleJiraIssueHandle({
				rightSidebarContext,
				actions,
				activeSelection,
				contentId,
				container,
				lastModified,
			})}
		>
			<FormattedMessage
				id="highlight-actions.create-jira-issue"
				defaultMessage="Jira issue"
				description="Create a new Jira issue"
			/>
		</Button>
	);
};
